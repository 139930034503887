import { Component, OnInit } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  authState;
  constructor(private amplifyService: AmplifyService,
    private router: Router) { }

  ngOnInit() {
    this.amplifyService.authStateChange$
      .subscribe(authState => {
        this.authState = authState.state;
        if (authState.state === 'signedIn') {
          this.router.navigate(['/']);
        }
      });
  }

}
